// Generated by Framer (1119174)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/al44hG4T9";

const cycleOrder = ["BjhRBmE0n"];

const serializationHash = "framer-mkZ6A"

const variantClassNames = {BjhRBmE0n: "framer-v-mnab2"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, label, width, ...props}) => { return {...props, aXhyNPv_v: label ?? props.aXhyNPv_v ?? "NEW"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;label?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, aXhyNPv_v, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "BjhRBmE0n", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-mkZ6A", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-mnab2", className)} data-framer-name={"Default"} layoutDependency={layoutDependency} layoutId={"BjhRBmE0n"} ref={ref ?? ref1} style={{backgroundColor: "var(--token-105880bc-8460-431a-95d8-28d1bc15afda, rgb(8, 8, 8))", borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10, ...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-1d2upin"} data-styles-preset={"al44hG4T9"} style={{"--framer-text-color": "var(--extracted-r6o4lv, var(--token-39e2b79f-dbe5-4d7a-a7e7-9459498c8818, rgb(0, 0, 0)))"}}>NEW</motion.p></React.Fragment>} className={"framer-11e738m"} layoutDependency={layoutDependency} layoutId={"RaHcR8m9F"} style={{"--extracted-r6o4lv": "var(--token-39e2b79f-dbe5-4d7a-a7e7-9459498c8818, rgb(0, 0, 0))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} text={aXhyNPv_v} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-mkZ6A [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-mkZ6A .framer-hw0sw { display: block; }", ".framer-mkZ6A .framer-mnab2 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 20px; justify-content: flex-start; padding: 0px 9px 0px 8px; position: relative; width: min-content; }", ".framer-mkZ6A .framer-11e738m { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-mkZ6A .framer-mnab2 { gap: 0px; } .framer-mkZ6A .framer-mnab2 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-mkZ6A .framer-mnab2 > :first-child { margin-left: 0px; } .framer-mkZ6A .framer-mnab2 > :last-child { margin-right: 0px; } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 20
 * @framerIntrinsicWidth 42.5
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","fixed"]}}}
 * @framerVariables {"aXhyNPv_v":"label"}
 * @framerImmutableVariables true
 */
const FramerriBA7qs9v: React.ComponentType<Props> = withCSS(Component, css, "framer-mkZ6A") as typeof Component;
export default FramerriBA7qs9v;

FramerriBA7qs9v.displayName = "UI — Label";

FramerriBA7qs9v.defaultProps = {height: 20, width: 42.5};

addPropertyControls(FramerriBA7qs9v, {aXhyNPv_v: {defaultValue: "NEW", displayTextArea: false, placeholder: "Label", title: "Label", type: ControlType.String}} as any)

addFonts(FramerriBA7qs9v, [...sharedStyle.fonts])